
















import { Component, Vue } from "vue-property-decorator";
import {
  InfoModal,
  LiquidityStakingInterface,
  StakingHeader,
} from "@/components";
import { getConfig } from "@/config";

@Component({
  components: {
    InfoModal,
    LiquidityStakingInterface,
    StakingHeader,
  },
})
export default class LiquidityStaking extends Vue {
  private pairs = [
    {
      name: "ETH",
      address: "ETH",
      stakingContractProxyAddress: getConfig().ethLiquidityStakingProxyAddress,
      tokenContractProxyAddress: getConfig().ethUniswapV2PairAddress,
    },
    {
      name: "DAI",
      address: getConfig().daiAddress,
      stakingContractProxyAddress: getConfig().daiLiquidityStakingProxyAddress,
      tokenContractProxyAddress: getConfig().daiUniswapV2PairAddress,
    },
  ];

  private pair = this.pairs[0];

  private get pairOptions(): any {
    return [
      { value: this.pairs[0], text: "ETH-WHYD" },
      { value: this.pairs[1], text: "DAI-WHYD" },
    ];
  }

  private refreshKey = 0;
  private refresh(): void {
    this.refreshKey += 1;
  }
}
